.app-logo {
  max-height: 60px;
}

.app-header {
  height: 100px;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #666;
}

.app h1 {
  margin: 0 0 24px;
  font-size: 24px;
  font-weight: 500;
  color: var(--primary);
}

.main-header {
  margin-bottom: 32px;
  padding: 32px 0;
  background-color: #f4f5f6;
}

.description {
  margin-bottom: 24px;
  font-size: 90%;
  font-style: italic;
}

.description p {
  margin: 0;
}

.selects {
  display: flex;
  gap: 16px;
}

.align-center {
  text-align: center;
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.table thead th {
  border-bottom: 1px solid #666;
}

.table th,
.table td {
  padding: .5rem;
}

.table tbody th,
.table tbody td {
  border-top: 1px solid #ddd;
}

@media (max-width: 576px) {
  .phone-hidden {
    display: none;
  }
}

@media (min-width: 768px) {
  .table th,
  .table td {
    padding: .5rem 1rem;
  }
}

@media (min-width: 992px) {
  .table th,
  .table td {
    padding: .75rem 1.5rem;
  }
}
