.no-result {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 40px 48px;
  width: fit-content;
  margin: 48px auto;
  color: #0d65ae;
  background-color: #0d65ae10;
}
