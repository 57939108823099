.explanation-text {
  display: flex;
  flex-direction: column;
  gap: 48px;
  text-align: justify;
}

.explanation-text > div {
  width: 100%;
  flex: 1 1 1;
}

@media (min-width: 768px) {
  .explanation-text {
    margin: 0 auto;
    padding: 16px 0;
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .explanation-text {
    padding: 32px 0;
    gap: 64px;
  }
}

@media (min-width: 1200px) {
  .explanation-text {
    gap: 80px;
  }
}
